<template>
	<div class="all">
		<AppTable
			primaryKey="id"
            :canCheck="canCheck"
            :canCheckAll="true"
			:columns="columns"
			:rows="tableData"
			:isProgressBar="isProgressBarLoading"
            :isStickyProgressBar="true"
            :rowsSelected="rowsSelected"
            :rowsSelectedPart="rowsSelectedPart"
            emptyMsg="Нет конструкций доступных для онлайн-бронирования"
			class="all-table"
			@lazyload="onLazyload"
			@sort="onSort"
			@search="onFilter"
            @onCheckRow="onCheckRow"
            @onCheckRowsAll="onCheckRowsAll"
		>
			<template
				v-for="(col,x) of [...modifieredCols, ...datesCols]"
				v-slot:[col]="scopeData"
			>
				<BaseCell
                    v-if="modifieredCols.includes(col)"
					:key="x"
					:content="scopeData.row[col]"
                    :is-show-tooltip="col === 'address' || col === 'gid'"
				/>
                <div
                    v-if="datesCols.includes(col)"
                    :key="x"
                    class="cell-date-wrap"
                >
                    <label class="checkbox-label">
						<div class="cell-checkbox">
							<base-checkbox-v2
								v-if="scopeData.row[col]"
								:key="x"
								:checked="getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column})"
							/>
						</div>
						<div
							class="cell-checkbox2"
							:class="{'cell-checkbox2_active': getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column})}"
                            :style="{
                                'border-color': isPriceNumber(scopeData.row[col]) ? getPriceColor({row: scopeData.row, column: scopeData.column}) : '',
                                'background': getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column}) ? getPriceColor({row: scopeData.row, column: scopeData.column}) : '',
                                'color': getCheckedSubCheckbox({row: scopeData.row, column: scopeData.column}) ? '#000' : '#ADB0BB'
                            }"
						>
							<div v-if="typeof scopeData.row[col] === 'object'">
                                <div
                                    v-if="!isCellEditing(col, scopeData.row.id)"
                                    @dblclick="onClickToEdit($event, col, scopeData.row.id)"
                                >
                                    <span
                                        v-if="scopeData.row[col].offer_price && scopeData.row[col].offer_price != scopeData.row[col].price && !isCellEditing(col, scopeData.row.id) && !isPriceBusy({row: scopeData.row, column: scopeData.column})"
                                    >
                                        <p
                                            style="text-decoration:line-through;font-size: 10px"
                                        >{{scopeData.row[col].price != '' ? parseInt(scopeData.row[col].price).toLocaleString('ru') : ''}}</p>
                                        <p
                                            :style="(scopeData.row[col].price < scopeData.row[col].offer_price) || !scopeData.row[col].price ? 'color:red' : 'color:green'"
                                        >{{scopeData.row[col].offer_price != '' ? parseInt(scopeData.row[col].offer_price).toLocaleString('ru') : ''}}</p>
                                    </span>
                                    <span v-else>
                                        {{scopeData.row[col].price != '' ? parseInt(scopeData.row[col].price).toLocaleString('ru') : ''}}
                                    </span>
                                </div>
                                <!-- <div
                                    contenteditable
                                    v-show="isCellEditing(col, scopeData.row.id)"
                                    @keydown.enter="onFinishEdit($event, col, scopeData.row.id)"
                                    v-tooltip="getEditingValue(scopeData.row[col])"
                                    v-clipboard="() => getEditingValue(scopeData.row[col])"
                                    @blur="onBlur($event, col, scopeData.row)"
                                >
                                    {{getEditingValue(scopeData.row[col])}}
                                </div> -->
                                <div
                                    v-else
                                >
                                    <base-text-input
                                        ref="baseTextInput"
                                        :value="String(getEditingValue(scopeData.row[col]))"
                                        @onBlur="onBlurV2($event, col, scopeData.row)"
                                        @keydown.enter="onFinishEdit($event, col, scopeData.row.id)"
                                    />
                                </div>
                            </div>
                            <span v-else>
                                <!-- <img
                                    :style="{'width': '15px', 'heigth': '15px'}"
                                    src="@/assets/images/icons/hui.svg"
                                > -->
                                <div :style="{'width': '15px', 'heigth': '15px', 'background': '#dce1e8'}" />
                            </span>
						</div>
					</label>
                </div>
			</template>

			<template v-slot:image_src="scopeData">
				<AppPhoto
					:small="scopeData.row.photo.small"
					:big="scopeData.row.photo.big"
                    class="all-table__photo"
				/>
			</template>

            <template v-slot:status="scopeData">
                <!-- <base-button class="cell__button-status" view="border" :data="scopeData">{{getBookingInfo(scopeData.row)}}</base-button> -->
                <booking-status
                    :status="getBookingStatus(scopeData.row)"
                    :id="getBookingId(scopeData.row)"
                    @toBook="toBook"
                />
            </template>

			<template v-slot:light="scopeData">
				<AppLight :isLight="scopeData.row.light == 1" />
			</template>

			<template v-slot:country_id="scopeData">
				<CountryFlag :abbreviation="scopeData.row.country_id" />
			</template>

			<template v-slot:oohdesk_id="scopeData">
				<AppIdentifier :firstId="scopeData.row.ooh_marker_id" :secondId="scopeData.row.oohdesk_id" />
			</template>

			<template v-slot:image_link="scopeData">
				<AppLink :link="scopeData.image_link" />
			</template>

			<template v-slot:image_map="scopeData">
				<MapImage :lng="scopeData.row.lng" :lat="scopeData.row.lat" />
			</template>

            <template v-slot:more_btn>
                <div class="cell-freeze">
                    <BaseDropdown
                        class="cell-dropdown"
                        :padding="0"
                        :is-fixed="false"
                        position="right"
                        :style-list="dropdownStyles"
                        @changeVisible="onChangeVisibleDropdown"
                    >
                        <template #preview>
                            <BaseButtonIcon
                                class="table-dropdown-button cell-dropdown__prev-btn"
                                iconName="tabler-icon-dots-vertical"
                                tooltipText=""
                            />
                        </template>

                        <template #content v-if="isShowDropdown">
                            <div class="cell-dropdown__container">
                                <BaseButton
                                    view="simple"
                                    form="oval"
                                    size="small"
                                >Удалить</BaseButton>
                            </div>
                        </template>
                    </BaseDropdown>
                </div>
            </template>
		</AppTable>
	</div>
</template>

<script>
import MixinHandbookTab from '@/mixins/MixinsHandbook/MixinHandbookTab';
import AppTable from '@/components/Table2/Table';
import AppPhoto from '@/components/CellComponents/Photo.vue';
import CountryFlag from '@/components/CellComponents/CountryFlag.vue';
import AppLight from '@/components/CellComponents/Light.vue';
import AppIdentifier from '@/components/CellComponents/Identifier.vue';
import AppLink from '@/components/CellComponents/Link.vue';
import BaseCell from '@/components/CellComponents/BaseCell.vue';
import MapImage from '@/components/CellComponents/MapImage.vue';
import BookingStatus from '@/components/CellComponents/BookingStatus';
import BaseCheckboxV2 from '@/components/Base/BaseCheckboxV2';
import BaseTextInput from '@/components/Base/BaseTextInput';
import BaseDropdown from "@/components/Base/BaseDropdown";
import BaseButtonIcon from "@/components/Base/BaseButtonIcon";
import BaseButton from "@/components/Base/BaseButton";

export default {
	name: "KitBudgetTabV2",
    mixins: [MixinHandbookTab],
	components: {
		AppTable,
		AppPhoto,
		CountryFlag,
		AppLight,
		AppIdentifier,
		AppLink,
		BaseCell,
		MapImage,
        BookingStatus,
        BaseCheckboxV2,
        BaseTextInput,
        BaseButton,
        BaseDropdown,
        BaseButtonIcon,
	},
    /**
     * @property {Object} editingCell - координаты редактируемой ячейки
     * @property {Boolean} isShowDropdown - Показать скрыть dropdowm menu
     * @property {Object} dropdownStyles - Стили для dropdowm menu
     */
    data: () => ({
        editingCell: {},
        isShowDropdown: false,
        dropdownStyles: {
            minWidth: '100px',
            top: '0',
            right: '45px',
            boxShadow: '0 0 10px 0 #aaa',
        },
    }),
	props: {
        datesCols: {
            type: Array,
			default: () => ([])
        },
        rowsSelected: {
            type: Array,
			default: () => ([])
        },
        rowsCheckboxes: {
            type: Object,
            default: () => ({})
        },
        rowsSelectedPart: {
            type: Array,
            default: () => ([])
        },
		folderId: {
			type: [Number, String],
			required: true
		},
        canCheck: {
            type: Boolean,
            default: false
        },
        isProgressBarLoading: {
            type: Boolean,
            default: false
        },
        selectedDates: {
            type: Array,
            default: () => ([])
        }
	},
	// computed: {},
    methods: {
        onCheckRow(checkData = {}) {
            this.$emit('onCheckRow', checkData);
        },
        onCheckRowsAll(isSelected = false) {
            this.$emit('onCheckRowsAll', isSelected);
        },
        // onCheckSubCheckbox(checkData = {}) {
        //     this.$emit('onCheckSubCheckbox', checkData);
        // },
        getCheckedSubCheckbox(checkData = {}) {
            const {column = {}, row = {}} = checkData;
            const {prop = ''} = column;
            const {id: rowId = -1} = row;
            const cellsChecked = this.rowsCheckboxes[rowId] || [];
            return cellsChecked.includes(prop);
        },
        isPriceNumber(price = '') {
            return price !== '' && price !== null;
        },
        /**
         * Включаю возможность редактирования
         */
        onClickToEdit($event, col, row) {
            this.editingCell = {
                col: col,
                row: row
            };
            // console.log($event.target);
            $event.target.focus()
            // setTimeout(() => {
            //     $event.target.focus()
            // }, 0)
            this.$nextTick(() => {
                this.$refs.baseTextInput[0].onFocus();
            })
        },
        /**
         * Эмичу новое значение
         */
        onBlur($event, col, rowObj) {
            if(!this.isCellEditing(col, rowObj.id)){
                return;
            }
            const content = $event.target.textContent;
            this.editingCell = {};
            this.changeValue({
                selected: [rowObj.id],
                dates: [col],
                changeValue: content,
                oldValue: this.getEditingValue(rowObj[col])
            })
        },
        onBlurV2(event, col, rowObj) {
            const content = event === null ? '' : event.target.value;
            if(!this.isCellEditing(col, rowObj.id)){
                return;
            }
            this.editingCell = {};
            this.changeValue({
                selected: [rowObj.id],
                dates: [col],
                changeValue: content,
                oldValue: this.getEditingValue(rowObj[col])
            })
        },
        /**
         * Эмитит измененное значение ячейки
         */
        changeValue(data) {
            data.changeValue = Number(data.changeValue.replace(/[^0-9]/g, ''));
            if(data.changeValue != data.oldValue){
                this.$emit('changeSinglePrice', data);
            }
        },
        /**
         * Завершаю редактирование при нажатии на "enter"
         */
        onFinishEdit($event, col, row) {
            if(!this.isCellEditing(col, row)){
                return;
            }
            $event.target.blur()
            this.editingCell = {};
        },
        isCellEditing(col, row){
            let flag = Object.keys(this.editingCell).length !== 0 && this.editingCell.col == col && this.editingCell.row == row;
            return flag;
        },
        getEditingValue(row){
            let value = row.offer_price ? row.offer_price : row.price;
            return value;
        },
        getBookingInfo(row) {
            const selectedDate = this.selectedDates[0] || null;
            const {booking = {}} = row;
            const bookingForDate = booking[selectedDate] || {};
            const {is_booked: isBooked = false, book_info: bookInfo = {}, id = '-1'} = bookingForDate;
            const {is_current: isCurrent = false} = bookInfo;
            let status = '';
            if (!isBooked)
                status = 'toBook';
            else if (isBooked && !isCurrent)
                status = 'notAvailable';
            else if (isBooked && isCurrent)
                status = 'booked';
            return {status, id};
        },
        getBookingStatus(row) {
            const bookingInfo = this.getBookingInfo(row);
            const {status = ''} = bookingInfo;
            return String(status);
        },
        getBookingId(row) {
            const bookingInfo = this.getBookingInfo(row);
            const {id = '-1'} = bookingInfo;
            return String(id);
        },
        toBook(id = '-1') {
            this.$emit('toBook', id);
        },
        /**
         * Событие показать/скрыть дропдаун
         * @param {Boolean} newVal - флаг показать/скрыть
         */
        onChangeVisibleDropdown(newVal) {
            this.isShowDropdown = newVal;
        },
    },
	filters: {
		cutNumber(value = '') {
            if (value !== '') {
                const priceLocale = parseInt(value).toLocaleString('ru');
                const priceLocaleArr = String(priceLocale).split(/\s/);
                const priceFormatted = priceLocaleArr.length >= 2 ? `${priceLocaleArr[0]}${'к'.repeat(priceLocaleArr.length - 1)}` : `${priceLocaleArr[0]}`;
                return priceFormatted;
            }
		}
	}
}
</script>

<style lang="scss" scoped>
$green-color: #71C376;

.all {
	display: flex;

	&-table {
		margin-top: 4px;

        ::v-deep .header {
            .format-cell .cell__content span,
            .ots-cell .cell__content span,
            .grp-cell .cell__content span {
                text-align: right !important;
            }

            .cell.date-cell {
                padding: 4px 15px !important;
            }
        }

        ::v-deep .body {
            .cell.address-cell .default-cell,
            .cell.direction-cell .default-cell,
            .cell.material-cell .default-cell {
                height: inherit;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: normal;
            }

            .cell.address-cell .default-cell {
                word-break: break-all;
            }
        }

        &__photo {
            width: 34px;
            height: 34px;
        }
	}

    .cell-date-wrap {
        width: 100%;
        height: 100%;
    }

	.checkbox-label {
		.cell-checkbox { display: none !important; }

		.cell-checkbox2 {
			width: 100%;
            height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

    //.cell__button-status {
    //    width: 105px;
    //    font-size: 11px;
    //    font-weight: 400;
    //    padding: 5px 10px;
    //    background-color: rgba(110, 200, 123, 0.4);
    //    border: 1px solid rgba(110, 200, 123, 0.5);
    //    box-sizing: border-box;
    //}

    .cell-dropdown {
        ::v-deep .dropdown__triangle {
            top: 12px !important;
            right: -18px !important;
            border-left: 9px solid #fff !important;
            border-bottom-color: transparent !important;
            z-index: 6 !important;
        }

        ::v-deep .dropdown {
            box-shadow: 0 3px 15px #21242d17 !important;
        }

        &__container {
            position: relative;
            background: #fff;
            border-radius: 6px;
            overflow: hidden;
            z-index: 5;
            padding: 10px;

            button {
                padding: 5px 10px;
                font-weight: 500;
                color: #ADB0BB;
                transition: all .4s ease-out;

                &:hover {
                    color: #000;
                }
            }
        }
    }

}
</style>
