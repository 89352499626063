<template>
    <div class="action-panel">
        <ActionPanelBlock
            :blockTitle="'Всего'"
            :blockCount="foundCount"
            :blockActions="foundActions"
            class="action-panel__block"
            @clickAction="onClickAction"
        />
        <ActionPanelBlock
            :blockTitle="'Выбрано'"
            :blockCount="selectedCount"
            :blockActions="selectedActions"
            class="action-panel__block"
            @clickAction="onClickAction"
        />
    </div> 
</template>

<script>
import ActionPanelBlock from './ActionPanelBlock.vue';
export default {
    name: 'ActionPanel',
    components: { ActionPanelBlock },
    props: {
        foundCount: {
            type: Number,
            default: 0
        },
        selectedCount: {
            type: Number,
            default: 0
        },
        foundActions: {
            type: Array,
            default: () => ([])
        },
        selectedActions: {
            type: Array,
            default: () => ([])
        }
    },
    methods: {
        onClickAction(action = {}) {
            this.$emit(action.emitName, action.key);
        }
    }
}
</script>

<style lang="scss" scoped>
    .action-panel {
        display: flex;

        &__block {
            &:not(:last-child) {
                margin-right: 12px;
            }
        }
    }
</style>