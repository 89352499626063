<template>
	<div class="price">
        <!-- <transition name="price__loading">
            <div v-if="isLoadingTotal" class="price__loading">
                <base-preloader-gif :size="40" />
            </div>
        </transition> -->
        <div class="price__kit-panel price__kit-panel--modified">
                <kit-panel
                    :kits="kitPanelItems"
                    :kitCustomIdSelected="kitCustomIdSelected"
                    :kitFoundActive="false"
                    :kitCanAdd="false"
                    :kitIsButtonContainer="false"
                    :is-modifided-design="isModified"
                    :isLoadingKitsCustom="isLoadingKitsCustom"
                    @onSelectKit="onSelectKit"
                    @onRenameKit="onRenameKit"
                />
            </div>
        <layout-tab
            v-for="(kit, kitIndex) in kitCustoms"
            ref="layoutTab"
            :key="kitIndex"
            :id="kit.id"
            :title="kit.title"
            :isCurrent="String(kitCustomIdSelected) === String(kit.id)"
            :is-modifided-design="isModified"
            @onRenameFolder="onRenameKit"
            @onClickFolder="onSelectKit"
            @removeSet="OnRemoveSet"
            @onLoading="onLoading"
        >
        </layout-tab>
	</div>
</template>

<script>
import MixinApi from "@/mixins/MixinsPageBayuerdesk/MixinApi";
import MixinKitPanel from "@/mixins/MixinsPageBayuerdesk/MixinKitPanel";
import KitPanel from "@/components/KitPanelV2/KitPanel";
import MixinTableFound from "@/mixins/MixinsPageBayuerdesk/MixinTableFound";
import LayoutTab from '@/layouts/LayoutsPageBayuerdesk/LayoutTab';
// import BasePreloaderGif from "@/components/Base/BasePreloaderGif.vue";
import {mapMutations} from "vuex";

export default {
	name: "PageBooking",
	components: {
        KitPanel,
		LayoutTab,
        // BasePreloaderGif
	},
    mixins: [
        MixinApi,
        MixinKitPanel,
        MixinTableFound
    ],
	data() {
		return {
            tableColumns: [],
            // isLoadingTotal: false,
            isLoadingKitsCustom: false,
            isModified: true,
		}
	},
    props: {
        selectedSetId: {
            type: String,
            default: ''
        }
    },
	computed: {
		modifieredCols() {
			const result = []
			this.columns.forEach(item => {
				if (item.isModifiered) {
					result.push(item.prop)
				}
			})

			return result
		},
	},
    mounted() {
        // this.isLoadingTotal = true;
        this.isLoadingKitsCustom = true;
        /** При монтировании получаем список наборов */
        this.postKitsList((kits) => {
            this.kitPanelItems = [];
            this.afterPostKitsList(kits);
            // this.isLoadingTotal = false;
            if(this.selectedSetId){
                this.kitCustomIdSelected = this.selectedSetId;
            }
            this.isLoadingKitsCustom = false;
        });
    },
    methods: {
        /** Проксируем мутации из стора */
        ...mapMutations('kit', ['setTableCustomPricesSelected', 'setTableCustomRows']),
        // eslint-disable-next-line no-unused-vars
        onLoading(isLoading = false) {
            // this.isLoadingTotal = isLoading;
        },
        /**
         * Событие выделения набора
         * @param {String} id - идентификатор элемента набора
         * @param {String} type - тип элемента набора (found|custom)
         */
        onSelectKit(id = '-1', type = '') {
            this.selectKit(id, type);
        },
        /**
         * Событие переименования набора
         * @param {String} id - идентификатор набора
         * @param {String} newName - новое название
         */
        onRenameKit(id = '-1', newName = '') {
            const postData = {set_id: id, name: newName};
            this.postRenameKit(postData, (id, newName) => {
                this.afterPostRenameKit(String(id), String(newName));
            });
        },
        OnRemoveSet(id) {
            // console.log(id);
            this.postRemoveKit(id, this.afterPostRemoveKitAndSetActive)
        },
    }
}
</script>

<style>
.layout .layout__content {
    padding: 0 20px;
}
</style>


<style lang="scss" scoped>
.price {
    position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;

	&__kit-panel {
		padding: 0;
		border-bottom: 1px solid $primary-color;

        &--modified {
            margin: 0 -20px;
            margin-bottom: 20px;
        }
	}
    &__loading {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99999;
        background: #fff;
        padding: 0px 40px 40px 40px;
        &-enter-active, &-leave-active {
            transition: opacity 300ms;
        }
        &-enter, &-leave-to {
            opacity: 0;
        }
    }
}
</style>
