<template>
	<div
        class="control-panel"
        :class="{'control-panel--modified' : isModifidedDesign}">
		<!-- <div
            class="control-panel__left"
            :class="{'control-panel__left--modified' : isModifidedDesign}"
        >
            <div class="selected">
                Выбрано {{selectedRows.length}} из {{ rowsCount }}
            </div>

            <div
                v-if="rowsCount > 0 && !isModifidedDesign"
                class="control-panel__remove-sides"
                @click="$emit('onRemoveSides')"
            >
                Удалить конструкции
            </div>
            <div
                v-if="rowsCount > 0 && !isModifidedDesign"
                class="control-panel__remove-sides"
                @click="$emit('onEditSides')"
            >
                Редактировать конструкции
            </div>
		</div> -->
		<div class="control-panel__right">
			<BaseDropdown
                v-if="isDatepicker"
				class="filter-panel__date-wrapper"
				:padding="0"
                :position="'left'"
                @changeVisible="onChangeVisible"
			>
				<template #preview>
						<!-- :style="{marginLeft: '25px'}" -->
					<BaseButton
						class="calendar-btn"
						view="border"
					>
                        <span class="calendar-btn__icon icon-tabler-icon-calendar-minus"></span>
						<span class="calendar-btn__title">{{dateTitle}}</span>
					</BaseButton>
				</template>

				<template #content v-if="isOpenedDatepicker">
					<DatePicker
						:isMultiple="!isModifidedDesign"
                        :accessDates="prepareDatesV2()"
                        :checkedDates="preparesSelectedDates"
                        @approve="OnSelectDates"
                        @cancel="onCloseDatepicker"
					/>
				</template>
			</BaseDropdown>

            <base-select
                v-if="!isModifidedDesign"
                :options="prices"
                :value="priceSelected"
                class="control-panel__select"
                @check="onCheckPrice"
            />

            <template v-if="!isModifidedDesign">
                <BaseButton
                    :style="{marginLeft: '14px'}"
                    :view="setModificationData.changeType === 'edit' ? 'green-border' : 'border'"
                    @click="setChangeType('edit')"
                >Заменить</BaseButton>
                <BaseButton
                    :style="{marginLeft: '10px'}"
                    :view="setModificationData.changeType === 'decrease' ? 'green-border' : 'border'"
                    @click="setChangeType('decrease')"
                >Уменьшить</BaseButton>

                <div :style="{margin: '0 14px'}">на</div>

                <div class="unit">
                    <input
                        type="text"
                        class="unit__title"
                        v-model="setModificationData.changeValue"
                        @input="onInputChangeType"
                    >
                    <div class="unit__control">
                        <div
                            class="unit-btn unit-btn-percent"
                            v-if="setModificationData.changeType === 'decrease'"
                            :class="setModificationData.valueType === 'percent' ? 'unit-btn_active' : ''"
                            @click="setValueType('percent')"
                        >%</div>
                        <div
                            class="unit-btn"
                            :class="setModificationData.valueType === 'number' ? 'unit-btn_active' : ''"
                            @click="setValueType('number')"
                        >₽</div>
                    </div>
                </div>
                <BaseButton
                    :disabled="selectedRows.length === 0"
                    :style="{marginLeft: '20px'}"
                    view="secondary"
                    @click="onCreateSetModification"
                >Применить</BaseButton>
            </template>
            <template v-else>
                <!-- <BaseButton
                    :style="{marginLeft: '20px'}"
                    view="border"
                    @click="setChangeType('decrease')"
                >Редактировать</BaseButton>
                <BaseButton
                    :style="{marginLeft: '20px'}"
                    view="border"
                    @click="onCreateSetModification"
                >Ок</BaseButton> -->
                <BaseButton
                    :disabled="selectedRows.length === 0"
                    :style="{marginLeft: '20px'}"
                    view="secondary"
                    @click="toBookMass"
                >
                    Забронировать
                </BaseButton>
            </template>
		</div>
	</div>
</template>

<script>
import BaseButton from "@/components/Base/BaseButton"
import BaseDropdown from "@/components/Base/BaseDropdown"
import DatePicker from "@/components/Base/DatePicker"
import BaseSelect from '@/components/Base/BaseSelect';
export default {
	name: "ControlPanel",
	components: {
		BaseButton,
		BaseDropdown,
		DatePicker,
        BaseSelect
	},
    data() {
        return {
            months: [
				'Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн',
				'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'
			],
            isOpenedDatepicker: false,
        }
    },
    /**
     * Входные данные
     * @property {String} id - идентификатор папки
     * @property {Boolean} isCurrent - активность набора папки
     */
    props: {
        prices: {
            type: Array,
            default: () => ([])
        },
        priceSelected: {
            type: String,
            default: ''
        },
        rowsCount: {
            type: Number,
            default: 0
        },
        selectedRows: {
            type: Array,
            default: () => ([])
        },
        selectedDates:{
            type: Array,
            default: () => ([])
        },
        calendarDates:{
            type: Array,
            default: () => ([])
        },
        setModificationData: {
            type: Object,
            default: () => ({})
        },
        isModifidedDesign: {
            type: Boolean,
            default: false
        },
        isDatepicker: {
            type: Boolean,
            default: false
        },
        dates: {
            type: Array,
            default: () => ([])
        }
    },
    computed:{
        preparesSelectedDates(){
            let selectedDates = [];
            this.selectedDates.forEach(date => {
                let split = date.split('-');
                selectedDates.push({year: Number(split[0]), month: Number(split[1])})
            });
            return selectedDates;
        },
        /**
         * заголовок для копки даты
         * @returns {String}
         */
        dateTitle() {
            if (this.preparesSelectedDates.length === 0) {
                return 'Выберите дату'
            } else {
                let uniqueMonths = []

                this.preparesSelectedDates.forEach(item => {
                    uniqueMonths.push(item.month)
                    uniqueMonths = Array.from(new Set(uniqueMonths))
                })
                uniqueMonths = uniqueMonths.map(item => this.months[item-1])
                const result = uniqueMonths.join(', ')
                return `${ result }`
            }
        },
    },
    methods:{
        onCheckPrice(price = {}) {
            const {id = ''} = price;
            this.$emit('onCheckPrice', String(id));
        },
        prepareDates(){
            let selectedDates = [];
            this.calendarDates.forEach(date => {
                let split = date.split('-');
                selectedDates.push({year: Number(split[0]), month: Number(split[1])})
            });
            return selectedDates;
        },
        prepareDatesV2() {
            return this.dates.map(date => ({ year: Number(date.split('-')[0]), month: Number(date.split('-')[1]) }));
        },
        OnSelectDates(selected){
            this.$emit('changedDates', selected);
            this.isOpenedDatepicker = !this.isOpenedDatepicker;
        },
        onCloseDatepicker(){
            this.isOpenedDatepicker = !this.isOpenedDatepicker;
        },
        onChangeVisible(value){
            this.isOpenedDatepicker = value;
        },
        setChangeType(type){
            this.setModificationData.changeType = type;
            if(type === 'edit'){
                this.setModificationData.valueType = 'number';
            }
            this.setModificationData.changeValue = 0;
            this.$emit('changedModificationData', this.setModificationData);
        },
        setValueType(type){
            this.setModificationData.valueType = type;
            this.$emit('changedModificationData', this.setModificationData);
        },
        onInputChangeType() {
            this.setModificationData.changeValue = Number(this.setModificationData.changeValue.replace(/[^0-9]/g, ''));
            this.$emit('changedModificationData', this.setModificationData);
        },
        onCreateSetModification(){
            if(!this.selectedDates.length){
                alert('Вы не выбрали дату');
            }else if(!this.setModificationData.changeValue && this.setModificationData.valueType == 'number'){
                alert('Вы не ввели сумму');
            }else if(!this.setModificationData.changeValue && this.setModificationData.valueType == 'percent'){
                alert('Вы не ввели процент');
            }else if(!this.selectedRows.length){
                alert('Вы не выбрали строки');
            }
            if(this.setModificationData.changeValue > 0 && this.selectedDates.length && this.selectedRows.length){
                this.$emit('createSetModification');
            }
        },
        toBookMass() {
            this.$emit('toBookMass', this.selectedRows, this.selectedDates);
        }
    }
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$active-darker-color: 		#1f79d2 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;

.control-panel {
	display: flex;
	align-items: center;
	// justify-content: flex-end;
	flex-wrap: wrap;

    &__select {
        margin-left: 14px;
        width: 170px;
    }

    &__select::v-deep .select-preview{
        padding: 10px 20px;
    }

    &__remove-sides {
        margin-left: 14px;
        color: #B9BBC2;
		font-size: 12px;
		cursor: pointer;
		display: inline-block;
		margin-right: 15px;
		transition: all .25s;

		&:hover {
			color: #000;
		}
    }

    &--modified {
        justify-content: flex-start;
    }

	&__left {
		display: flex;
		align-items: center;
        margin-right: auto;

        &--modified {
            margin-right: 0;
        }
	}

	&__right {
		// margin-left: 100px;
		display: flex;
		align-items: center;
	}

	.selected {
		font-weight: 500;
	}
	.calendar-btn {
		display: flex;
		align-items: center;
		// margin-left: 25px;

		&__title {
			margin-left: 10px;
		}
	}

	.unit {
		border: 1px solid $primary-color;
		height: 36px;
		padding: 0 15px;
		display: flex;
		align-items: center;
		border-radius: 4px;

        &__title {
            width: 30px;
            display: block;
            border: none;
            outline: none;
        }

		&__control {
			margin-left: 10px;
			display: flex;

			.unit-btn {
				display: flex;
				align-items: center;
				justify-content: center;
				color: #B9BBC2;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				background: $primary-color;
				cursor: pointer;

				&:not(:last-child) {
					margin-right: 10px;
				}

				&_active {
                    border: 1px solid;
					border-color: $success-color !important;
                    background: #fff;
					color: #000000;

                    &.unit-btn-percent {
                        border: 1px solid;
                        border-color: $success-color !important;
                        background: #fff;
                        color: #000000;
                    }
				}
			}
		}

	}
}
</style>
