import { render, staticRenderFns } from "./LayoutTab.vue?vue&type=template&id=7b65ee39&scoped=true&"
import script from "./LayoutTab.vue?vue&type=script&lang=js&"
export * from "./LayoutTab.vue?vue&type=script&lang=js&"
import style0 from "./LayoutTab.vue?vue&type=style&index=0&id=7b65ee39&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b65ee39",
  null
  
)

export default component.exports