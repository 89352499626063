<template>
    <div class="booking-legend">
        <div
            v-for="(statusKey, statusIndex) in Object.keys(statusesInfo)"
            :key="statusIndex"
            class="booking-legend__item"
        >
            <div
                class="booking-legend__item-preview"
                :style="getStatusPreview(statusKey)"
            >
            </div>
            <div class="booking-legend__item-title">{{getStatusTitle(statusKey)}}</div>
        </div>
    </div>
</template>

<script>
import SchemePageBooking from '@/schemes/SchemePageBooking';
const {statusesInfo} = SchemePageBooking;
/** Компонент легенды для бронирования */
export default {
    name: 'BookingLegend',
    /**
     * Данные компонента
     * @property {Object} statusesInfo - информация статусов бронирования
     */
    data: () => ({
        statusesInfo: statusesInfo
    }),
    methods: {
        /**
         * Получить заголовк статуса
         * @param {String} statusKey - ключ статуса
         * @returns {String}
         */
        getStatusTitle(statusKey = '') {
            const statusObj = this.statusesInfo[statusKey];
            const {title = ''} = statusObj;
            return String(title);
        },
        /**
         * Получить стиль для превью статуса
         * @param {String} statusKey - ключ статуса
         * @returns {Object}
         */
        getStatusPreview(statusKey = '') {
            const statusObj = this.statusesInfo[statusKey];
            const {style = {}} = statusObj;
            return style;
        }
    }
}
</script>

<style lang="scss" scoped>
    .booking-legend {
        display: flex;
        margin-left: 15px;

        &__item {
            display: flex;
            margin-right: 20px;
            &-preview {
                width: 20px;
                height: 20px;
                margin-right: 7px;
            }
            &-title {
                display: flex;
                align-items: center;
                font-size: 12px;
            }
        }
    }
</style>
