<template>
    <div class="change">
        <div class="change__info">
            <div class="change__title">
                <base-preloader-skeleton />
                Phantom
            </div>
            <div class="change__value">
                <base-preloader-skeleton />
                0 %
            </div>
        </div>
        <div class="change__close">
            <base-preloader-skeleton class="change__close-preloader"/>
            <BaseIcon icon="icon-tabler-icon-trash" class="change__close-icon" />
        </div>
    </div>
</template>

<script>
import BaseIcon from "@/components/Base/BaseIcon"
import BasePreloaderSkeleton from "@/components/Base/BasePreloaderSkeleton";
export default {
    name: 'AcceptedChangePhantom',
    components: {
        BaseIcon,
        BasePreloaderSkeleton
    }
}
</script>

<style lang="scss" scoped>
    $assistant-color: #ADB0BB !default;

    .change {
		background: #FFFFFF;
		padding: 12px;
		display: flex;
		align-items: center;
		position: relative;
		border-radius: 10px;

		&:not(:last-child) { margin-bottom: 10px; }

		&__info {
			font-size: 10px;
		}

		&__title {
            position: relative;
			width: 100px;
		}

		&__value {
            width: 50px;
            position: relative;
			margin-top: 4px;
			color: $assistant-color;
		}

		&__close {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 10px;
			width: 24px;
			height: 24px;
			background: $primary-lighten-color;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			&:hover {
				color: $danger-color;
			}

			&-icon {
				font-size: 12px;
				color: $assistant-color;
			}

            &-preloader {
                border-radius: inherit;
            }
		}
	}
</style>
