<template>
    <div class="action-panel-block">
        <div class="action-panel-block__info">
            <div class="action-panel-block__title">
                {{ blockTitle }}
            </div>

            <div class="action-panel-block__count">
                {{ blockCount }}
            </div>
        </div>

        <div
            v-for="action in blockActions"
            :key="action.key"
            :class="[
                'action-panel-block__action',
                { 'action-panel-block__action_active': action.isActive }
            ]"
            @click="onClickAction(action)"
        >
            <div class="action-panel-block__action-icon">
                <component :is="action.iconComponentName"/>
            </div>
            <div class="action-panel-block__action-name">
                {{ action.name }}
            </div>
        </div>
    </div>
</template>

<script>
import IconSelectAll from '../Icons/IconSelectAll.vue';
import IconDownload from '../Icons/IconDownload.vue';
import IconReset from '../Icons/IconReset.vue';
import IconEdit from '../Icons/IconEdit.vue';
import IconDelete from '../Icons/IconDelete.vue';

export default {
    name: 'ActionPanelBlock',
    components: { 
        IconSelectAll,
        IconDownload,
        IconReset,
        IconEdit,
        IconDelete
    },
    props: {
        blockTitle: {
            type: String,
            default: ''
        },
        blockCount: {
            type: Number,
            default: 0
        },
        blockActions: {
            type: Array,
            default: () => ([])
        }
    },
    methods: {
        onClickAction(action = {}) {
            this.$emit('clickAction', action);
        }
    }
}
</script>

<style lang="scss" scoped>
    .action-panel-block {
        display: flex;
        padding: 3px 15px;
        border-radius: 10px;
        border: 1px solid #E6EBF0;

        @mixin title {
            font-size: 12px;
            line-height: 18px;
        }

        &__info {
            display: flex;
            align-items: center;
        }

        &__title {
            @include title;
            font-weight: 400;
            margin-right: 5px;
        }

        &__count {
            @include title;
            font-weight: 700;
            margin-right: 10px;
        }

        &__action {
            display: flex;
            padding: 7px;
            cursor: pointer;
            border-radius: 10px;

            &:hover {
                background-color: #f2f2f2;
            }

            &:not(:last-child) {
                margin-right: 12px;
            }

            &-name {
                @include title;
                font-weight: 400;
                display: flex;
                align-items: center;
            }

            &-icon {
                margin-right: 8px;
                width: 18px;
                height: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &_active {
                background-color: #f2f2f2;
            }
        }
    }
</style>