<template>
    <div class="layout-tab__container">
        <div class="page-price__header">
            <div class="page-price__header-left">
                <RouterLink to="/buyerdesk">
                    <BaseButton
                        class="page-price__prev"
                        form="oval"
                    >
                        <span class="page-price__prev-icon icon-tabler-icon-chevron-left"></span>
                    </BaseButton>
                </RouterLink>

                <div class="page-price__header-title">
                    {{ title }}
                </div>
            </div>

            <div class="page-price__header-right">
                <BaseButton
                    v-tooltip="'Удалить набор'"
                    view="border"
                    class="layout-tab__button-right layout-tab__button-right--modified"
                    @click="onRemoveTab"
                >
                    <span class="layout-tab__icon icon-tabler-icon-trash"></span>
                </BaseButton>

                <BaseButton
                    v-if="!isModifidedDesign"
                    view="secondary"
                    @click="onOpenCreateOfferPopup"
                >
                    Отправить
                </BaseButton>
            </div>
        </div>

        <div class="page-price__filter-panel">
            <BaseTabList
                :tabList="tabModeList"
                :tabIdActive.sync="tabModeIdActive"
                class="page-price__filter-panel-item"
            />

            <div
                v-if="isUncoverShow"
                class="page-price__uncover page-price__filter-panel-item"
            >
                <BaseCheckboxV3
                    :checked="isUncover"
                    @change="onChangeIsUncover"
                />
                <div class="page-price__uncover-title">Раскрыть</div>
            </div>

            <ReconciliationFilter
                :filterItems="filterItems"
                :pageType="'price'"
                :isLoading="isLoadingFilter"
                class="page-price__filter page-price__filter-panel-item"
                @onSelectFilterItem="onSelectFilterItem"
                @onRemoveAllFilterChips="onRemoveAllFilterChips"
            />
        </div>

        <ActionPanel
            :foundCount="totalRows"
            :selectedCount="selectedRows.length"
            :foundActions="foundActions"
            :selectedActions="selectedActions"
            class="page-price__action-panel"
            @createModification="onCreateModification"
            @export="onExport"
            @removeSides="onRemoveSides"
            @selectAll="onSelectAll"
            @clearAll="onClearAll"
        />

        <div
            v-show="isCurrent"
            class="layout-tab__body"
            :class="{'layout-tab__body--modified' : isModifidedDesign}"
        >
            <div class="layout-tab__left">
                <div :class="{'layout-tab__wrapper' : isModifidedDesign}">
                    <!-- <div class="layout-tab__buttons">
                        <div
                            v-if="tableRows.length"
                            class="layout-tab__buttons-left"
                            :class="{'layout-tab__buttons-left--modified' : isModifidedDesign}"
                        >
                            <BaseButton
                                v-if="!isModifidedDesign"
                                view="secondary"
                                @click="onOpenCreateOfferPopup"
                            >Создать предложение</BaseButton>
                        </div>
                        <div class="layout-tab__buttons-right">
                            <BaseButton
                                v-if="tableRows.length && !isModifidedDesign"
                                view="border"
                                class="layout-tab__button-right"
                                @click="onRemoveSides"
                            >Удалить конструкции</BaseButton>

                            <BaseButton
                                v-tooltip="'Скачать Excel'"
                                view="border"
                                class="layout-tab__button-right layout-tab__button-right--modified"
                                @click="onExportFolder(id)"
                            >
                                <span class="layout-tab__icon icon-tabler-icon-file-download"></span>
                            </BaseButton>

                            <BaseButton
                                v-tooltip="'Удалить набор'"
                                view="border"
                                class="layout-tab__button-right layout-tab__button-right--modified"
                                @click="onRemoveTab"
                            >
                                <span class="layout-tab__icon icon-tabler-icon-trash"></span>
                            </BaseButton>
                        </div>
                    </div> -->

                    <div
                        v-if="isActiveActionModification"
                        class="layout-tab__control"
                    >
                        <ControlPanel
                            :rowsCount="!isModifidedDesign ? tableRows.length : tableRowsForReservePage.length"
                            :selectedRows="selectedRows"
                            :calendarDates="tableColumnsDates"
                            :dates="dates"
                            :selectedDates="selectedDates"
                            :setModificationData="setModificationData"
                            :is-modifided-design="isModifidedDesign"
                            :prices="pricesOptions"
                            :priceSelected="priceOptionSelected"
                            :isDatepicker="isDatepicker"
                            @onCheckPrice="onCheckPrice"
                            @changedDates="onChangeSelectedDates"
                            @removeTab="onRemoveTab"
                            @changedModificationData="onChangeModificationData"
                            @createSetModification="onCreateSetModification"
                            @openOfferPopup="onOpenCreateOfferPopup"
                            @toBookMass="toBookMass"
                            @onRemoveSides="onRemoveSides"
                            @onEditSides="onOpenEditSides"
                        />
                    </div>
                </div>

                <div class="layout-tab__summary">
                    <SummaryData
                        v-if="!isModifidedDesign"
                        :summaryData="summaryData"
                        class="folder__summary"
                    />
                    <booking-legend v-if="isModifidedDesign"/>
                </div>

                <div class="layout-tab__table-wrapper">
                    <kit-budget-tab
                        v-if="!isModifidedDesign"
                        :isProgressBarLoading="isProgressBarLoading"
                        :canCheck="true"
                        :columns="tableColumns"
                        :modifieredCols="tableColumnsModifered"
                        :datesCols="tableColumnsDates"
                        :tableData="tableRows"
                        :folderId="id"
                        :rowsCheckboxes="tableRowsPricesSelected"
                        :rowsSelected="selectedRows"
                        class="layout-tab__table"
                        @onCheckRow="onCheckRow"
                        @onCheckRowsAll="onCheckRowsAll"
                        @filter="onFilter"
                        @sort="onSort"
                        @changeSinglePrice="onChangeSinglePrice"
                        @changePeriod="onChangePeriod"
                        @onChangeNds="onChangeNds"
                        @onChangeTableColumns="changeTableColumns"
                        @lazyload="onLazyLoadTable"

                    />
                    <kit-budget-tab-v2
                        v-else
                        :isProgressBarLoading="isProgressBarLoading"
                        :canCheck="true"
                        :columns="tableColumnsForReservePage"
                        :modifieredCols="tableColumnsModifered"
                        :datesCols="tableColumnsDates"
                        :tableData="tableRowsForReservePage"
                        :folderId="id"
                        :rowsCheckboxes="tableRowsPricesSelected"
                        :selectedDates="selectedDates"
                        :rowsSelected="selectedRows"
                        class="layout-tab__table"
                        @onCheckRow="onCheckRow"
                        @onCheckRowsAll="onCheckRowsAll"
                        @filter="onFilter"
                        @sort="onSort"
                        @changeSinglePrice="onChangeSinglePrice"
                        @toBook="toBook"
                    />
                </div>
            </div>

            <template v-if="!isModifidedDesign">
                <div class="layout-tab__right">
                    <AcceptedChanges
                        class="layout-tab__changes"
                        :isChangesLoading="isProgressBarLoading"
                        :setModificationList="setModificationList"
                        @onRemoveModification="onRemoveModification"
                        @onChangeTitle="onChangeModificationTitle"
                    />
                </div>
            </template>

            <ModalCreateOffers
                v-if="isCreateOfferPopupOpen"
                title="Создание предложения Oohdesk"
                :tags="offerTags"
                @onCloseModal="onCloseCreateOfferPopup"
                @send="sendFolder"
            />

            <ExportTemplate
                v-if="isExporting"
                :userTemplates="userTemplates"
                :currentTemplate="currentTemplate"
                @onGetTemplates="onGetTemplates"
                @onCloseExportFolder="onCloseExportFolder"
                @onSaveTemplate="onSaveTemplate"
                @onCheckTemplate="onCheckTemplate"
                @onExportTemplate="onExportTemplate"
                @onRemoveTemplate="onRemoveTemplate"
            />
            <modal-edit-sides
                v-if="isEditSides"
                @onCloseModal="onCloseModalEditSides"
                @onEditSides="onEditSides"
            />
        </div>
    </div>
</template>

<script>
import {mapMutations} from 'vuex';
import ServiceApi from '@/services/ServiceApi.js';
import MixinTableFound from '@/mixins/MixinsPageBayuerdesk/MixinTableFound';
import MixinSummary from '@/mixins/MixinSummary';
import KitBudgetTab from '@/components/HandbookComponents/KitBudgetTab';
import KitBudgetTabV2 from '@/components/HandbookComponents/KitBudgetTabV2';
import ControlPanel from "@/components/PricePageComponents/ControlPanel";
import BookingLegend from "@/components/PricePageComponents/BookingLegend"
import AcceptedChanges from "@/components/PricePageComponents/AcceptedChanges";
// import AppPopup from "@/components/Popup";
import BaseButton from "@/components/Base/BaseButton";
import SummaryData from "@/components/Folder/SummaryData";
import MixinApi from "@/mixins/MixinsPageBayuerdesk/MixinApi";
import ExportTemplate from "@/components/Export/ExportTemplate";
import MixinExport from "@/mixins/MixinExport";
// import BaseSelectMultipleV2 from '../../components/Base/BaseSelectMultipleV2.vue';
import ModalCreateOffers from '../../components/MapV2/components/CreateModal/CreateModal.vue';
import ActionPanel from '../../components/ActionPanel/ActionPanel.vue';
import ServiceEditPricesTable from '../../services/ServicesEditPrices/ServiceEditPricesTable.js';
import ReconciliationFilter from "../../components/Reconciliation/ReconciliationFilter/ReconciliationFilter.vue";
import BaseTabList from "../../components/Base/BaseTabList.vue";
import BaseCheckboxV3 from '@/components/Base/BaseCheckboxV3';
import ServicePriceFilter from '../../services/ServicesPrice/ServicePriceFilter.js';
import lodash from "lodash";

export default {
    name: 'LayoutTab',
    components: {
        BaseButton,
        KitBudgetTab,
        KitBudgetTabV2,
        ControlPanel,
        // AppPopup,
        SummaryData,
        AcceptedChanges,
        ExportTemplate,
        BookingLegend,
        // BaseSelectMultipleV2,
        ModalCreateOffers,
        ActionPanel,
        ReconciliationFilter,
        BaseTabList,
        BaseCheckboxV3
    },
    mixins: [
        MixinTableFound,
        MixinSummary,
        MixinApi,
        MixinExport
    ],
    /**
     * Входные данные
     * @property {String} id - идентификатор папки
     * @property {Boolean} isCurrent - активность набора папки
     */
    props: {
        id: {
            type: String,
            default: '-1'
        },
        isCurrent: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        isModifidedDesign: {
            type: Boolean,
            default: false
        },
    },
    /**
     * @property {Array} selectedRows - выделенные строки
     * @property {Array} selectedDates - выбранные даты (столбцы)
     * @property {Array} selectedDatesObjs - выбранные даты (столбцы) в виде объекта {year:2021,month:5}
     * @property {Object} setModificationData - настройки панели изменения цена
     * @property {Array} setModificationList - список модификаций
     */
    data() {
        return {
            selectedRows: [],
            selectedDates: [],
            selectedDatesObjs: [],
            setModificationData: {
                changeType: 'edit',
                valueType: 'number',
                changeValue: 0
            },
            setModificationList: [],
            isCreateOfferPopupOpen: false,
            isProgressBarLoading: false,
            pricesOptions: [
                {
                    id: 'offer_price',
                    value: 'Цена без НДС'
                },
                {
                    id: 'install_price',
                    value: 'Монтаж без НДС'
                },
                {
                    id: 'print_price',
                    value: 'Печать без НДС'
                },
                {
                    id: 'additional_install_price',
                    value: 'Доп. монтаж без НДС'
                }
            ],
            priceOptionSelected: 'offer_price',
            isEditSides: false,
            offerTags: [],
            foundActions: [
                {
                    key: 'selectAll',
                    name: 'Выбрать все',
                    iconComponentName: 'IconSelectAll',
                    isActive: false,
                    emitName: 'selectAll'
                },
                {
                    key: 'export',
                    name: 'Скачать Excel',
                    iconComponentName: 'IconDownload',
                    isActive: false,
                    emitName: 'export'
                }
            ],
            selectedActions: [
                {
                    key: 'clearAll',
                    name: 'Сбросить выбор',
                    iconComponentName: 'IconReset',
                    isActive: false,
                    emitName: 'clearAll'
                },
                {
                    key: 'createModification',
                    name: 'Модификация',
                    iconComponentName: 'IconEdit',
                    isActive: false,
                    emitName: 'createModification'
                },
                {
                    key: 'removeSides',
                    name: 'Удалить',
                    iconComponentName: 'IconDelete',
                    isActive: false,
                    emitName: 'removeSides'
                }
            ],
            filterItems: [...ServicePriceFilter.filterDataScheme],
            isLoadingFilter: false,
            tabModeList: [
                {
                    id: 'month',
                    title: 'Месяц'
                },
                {
                    id: 'period',
                    title: 'Период'
                }
            ],
            tabModeIdActive: 'month',
            isUncover: true,
        }
    },
    computed: {
        isUncoverShow() {
            return this.tabModeIdActive === 'month';
        },
        filterItemsSelected() {
            const filterItemsSelected = this.filterItems.reduce((filterItemsSelected, filterItem) => {
                filterItemsSelected = {
                    ...filterItemsSelected,
                    [filterItem.id]: filterItem?.valuesSelected ?? []
                };

                return filterItemsSelected;
            }, {});

            return filterItemsSelected;
        },
        isActiveActionModification() {
            const actionModification = this.selectedActions.find(action => action.key === 'createModification');

            return actionModification.isActive;
        },
        /**
         * Тело запроса для таблицы
         * @returns {Object}
         */
        tableRequestBody() {
            const tableRequestBody = {
                set_id: this.id,
                sort: this.tableSort,
                filter: this.tableFilter,
                pagination: {
                    per_page: parseInt(this.tableFoundPerPage),
                    page: parseInt(this.tableFoundPageNumber)
                }
            };
            return tableRequestBody;
        },
        /**
         * Тело базового запроса
         * @returns {Object}
         */
        baseRequestBody() {
            const setRequestBody = {
                set_id: this.id,
            };
            return setRequestBody;
        },
        /**
         * Тело запроса для применения модификаций к выбранным столбцам
         * @returns {Object}
         */
        addSetPriceRequestBody() {
            const setPriceRequestBody = {
                set_id: this.id,
                dates: this.selectedDates,
                selected: this.selectedRows,
                change_type: this.setModificationData.changeType,
                value_type: this.setModificationData.valueType,
                change_value: this.setModificationData.changeValue,
                change_attribute: this.priceOptionSelected,
                filtered: {
                    set_id: this.id,
                    ...this.filterItemsSelected
                }
            };
            return setPriceRequestBody;
        },
        /**
         * Выбранные цены набора
         * @returns {Object}
         */
        tableRowsPricesSelected() {
            let tableRowsPricesSelected = {};
            this.tableRows.forEach(row => {
                const {id = '-1', prices = []} = row;
                tableRowsPricesSelected[id] = prices.map(price => {
                    const {date = ''} = price;
                    return date;
                })
            });
            return tableRowsPricesSelected;
        },
        /**
         * Выбранные id цен набора
         * @returns {Object}
         */
        tableRowsPricesIdsSelected() {
            let tableRowsPricesSelected = {};
            this.tableRows.forEach(row => {
                const {id = '-1', prices = []} = row;
                tableRowsPricesSelected[id] = prices.map(price => {
                    const {id = '-1'} = price;
                    return id;
                })
            });
            return tableRowsPricesSelected;
        },
        isDatepicker() {
            return this.priceOptionSelected === 'offer_price';
        },
        dates() {
            const dates = this.tableRows.reduce((dates, tableRow) => {
                const tableRowDates = tableRow?.prices.map(price => String(price?.date ?? ''));
                const datesDifference = lodash.difference(tableRowDates, dates);
                dates.push(...datesDifference);

                return dates;
            }, []);

            return dates;
        }
    },
    watch: {
        tabModeIdActive() {
            this.tableFoundPageNumber = 1;
            this.postTableBudgetDataCustomKit((rows) => this.afterPostTableBudgetData(rows, 'prices', true, this.tabModeIdActive, this.isUncover));
        },
        tableColumnsDates() {
            if (this.tableColumnsDates.length && !this.selectedDates.length) {
                //Устанавливаю последнюю дату по умолчанию
                const dates = this.tableColumnsDates.filter(column => !['print_price', 'install_price', 'additional_install_price'].includes(String(column)));
                let lastDate = dates[dates.length - 1];
                this.selectedDates.push(String(lastDate).split('__')[0]);
            }
        },
        /**
         * Следим является ли набор текущим выбранным,
         * при необходимости делаем запрос на данные
         * и устанавливаем в стор выбранные цены
         */
        isCurrent() {
            if (this.isCurrent) {
                this.getSelectedTableRows();
                this.getFilterData();

                this.isProgressBarLoading = true;
                if (this.tableRows.length === 0) {
                    this.postTableBudgetDataCustomKit((rows) => {
                        this.afterPostTableBudgetData(rows, 'prices', false, this.tabModeIdActive, this.isUncover);
                        this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                        this.setTableCustomRows(this.tableRows);
                    });
                } else {
                    this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                    this.setTableCustomRows(this.tableRows);
                }
                if (this.setModificationList.length === 0) {
                    this.postSetModificationsData((list) => {
                        this.setModificationList = list;
                        this.isProgressBarLoading = false;
                    })
                } else
                    this.isProgressBarLoading = false;

                this.postSummaryData();
            }
        }
    },
    async mounted() {
        /**
         * При монтировании проверяем является ли набор текущим выбранным,
         * при необходимости делаем запрос на данные
         * и устанавливаем в стор выбранные цены
         */
        if (this.isCurrent) {
            this.isProgressBarLoading = true;

            if (this.tableRows.length === 0) {
                this.$emit('onLoading', true);
                this.postTableBudgetDataCustomKit((rows) => {
                    this.afterPostTableBudgetData(rows, 'prices', false, this.tabModeIdActive, this.isUncover);
                    this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                    this.setTableCustomRows(this.tableRows);
                    this.$emit('onLoading', false);
                });
            } else {
                this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                this.setTableCustomRows(this.tableRows);
            }

            if (this.setModificationList.length === 0) {
                this.postSetModificationsData((list) => {
                    this.setModificationList = list;
                    this.isProgressBarLoading = false;
                })
            } else {
                this.isProgressBarLoading = false;
                this.postSummaryData();
            }
        }
    },
    methods: {
        /** Проксируем мутации из стора */
        ...mapMutations('kit', ['setTableCustomPricesSelected', 'setTableCustomRows']),
        async getFilterData() {
            this.isLoadingFilter = true;

            const filterResponse = await ServicePriceFilter.getFilter(this.id, this.filterItems);
            this.filterItems = [...filterResponse.items];
            this.totalRows = filterResponse.count;

            this.isLoadingFilter = false;
        },
        onSelectFilterItem(filterComponentItems = [], filterItemId = "") {
            const filterItemIndex = this.filterItems.findIndex((filterItem) => String(filterItem?.id) === String(filterItemId));

            if (filterItemIndex !== -1) {
                this.filterItems.splice(filterItemIndex, 1, {
                    ...this.filterItems[filterItemIndex],
                    valuesSelected: [...filterComponentItems],
                });
            }

            this.selectFilterItemAfter();
        },
        onRemoveAllFilterChips(filterItems = []) {
            this.filterItems = [...filterItems];

            this.selectFilterItemAfter();
        },
        selectFilterItemAfter() {
            this.tableFoundPageNumber = 1;
            this.getFilterData();
            this.postSummaryData();
            this.postTableBudgetDataCustomKit((rows) => {
                this.afterPostTableBudgetData(rows, 'prices', true, this.tabModeIdActive, this.isUncover);
            });
        },
        onChangeIsUncover() {
            this.isUncover = !this.isUncover;
            this.tableFoundPageNumber = 1;

            this.postTableBudgetDataCustomKit((rows) => this.afterPostTableBudgetData(rows, 'prices', true, this.tabModeIdActive, this.isUncover));
        },
        onCreateModification(actionKey = '') {
            const index = this.selectedActions.findIndex(action => action.key === actionKey);
            const actionModificationNew = {
                ...this.selectedActions[index],
                isActive: !this.selectedActions[index].isActive
            };

            this.selectedActions.splice(index, 1, actionModificationNew);
        },
        onExport() {
            this.onExportFolder(this.id);
        },
        onSelectAll() {
            this.onCheckRowsAll(true);
        },
        onClearAll() {
            this.onCheckRowsAll(false);
        },
        changeTableColumns(tableColumns = []) {
            this.setTableColumnsSchemeCustom(tableColumns);
        },
        onLazyLoadTable() {
            if (this.tableFoundPageNumber >= this.tableTotalPages) return;
            if (this.isProgressBarLoading === true) return;

            this.isProgressBarLoading = true;
            this.tableFoundPageNumber += 1;
            this.postTableBudgetDataCustomKit((rows) => {
                this.afterPostTableBudgetData(rows, 'prices', false, this.tabModeIdActive, this.isUncover);
                this.isProgressBarLoading = false;
            });
            // this.postTableBudgetDataCustomKit(() => {
            //    this.isProgressBarLoading = false;
            // });
        },
        async setTableColumnsSchemeCustom(tableColumns = []) {
            const tableColumnsSchemeCustom = await ServiceEditPricesTable.setTableColumnsSchemeCustom(tableColumns);

            this.tableColumns = [...tableColumnsSchemeCustom];
        },
        async postSummaryData() {
            try {
                const response = await ServiceApi.post('free', '/map/set-summary', {set_id: this.id, ...this.filterItemsSelected});
                const result = response.data.data
                this.summaryData = this.AdapterSummaryData(result, this.isModifidedDesign);
            } catch (error) {
                console.log(error);
            }
        },
        /**
         * Запрос на получение табличных данных наора
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postTableBudgetDataCustomKit(actionAfter = () => {
        }) {
            try {
                let rowsBooking = {};
                if (this.isModifidedDesign) {
                    const requestBodyBooking = {set_id: this.id};
                    const responseBodyBooking = await ServiceApi.post('free', '/map/book-info', requestBodyBooking);
                    const {data: {data: dataBooking = {}}} = responseBodyBooking;
                    rowsBooking = {...dataBooking};
                }
                const selected = Object.keys(rowsBooking).length > 0 ? Object.keys(rowsBooking) : ['-1'];
                const requestBody = this.isModifidedDesign ? {
                    ...this.tableRequestBody,
                    selected: selected
                } : this.tableRequestBody;
                const res = await ServiceApi.post('free', '/map/set-table-budget', {...requestBody, ...this.filterItemsSelected});
                const {data = {}} = res;
                const {data: dataCustom = {}} = data;
                const {rows = []} = dataCustom;
                let rowsBuffer = [...rows];
                if (this.isModifidedDesign) {
                    const rowsWithBooking = rows.map(row => {
                        const {id = ''} = row;
                        const rowBooking = rowsBooking[id];
                        return {...row, booking: rowBooking};
                    });
                    rowsBuffer = [...rowsWithBooking];
                }
                actionAfter(rowsBuffer);
            } catch (error) {
                console.log(error);
            }
        },
        /**
         * Запрос на получение табличных данных наора
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postSetModificationsData(actionAfter = () => {
        }) {
            try {
                const res = await ServiceApi.post('free', '/map/set-modifications', this.baseRequestBody);
                const {data = {}} = res;
                const {data: dataCustom = {}} = data;
                actionAfter(dataCustom);
            } catch (error) {
                console.log(error);
            }
        },
        /**
         * Запрос на применение модификаций к выбранным ценам
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         * @param {Object|Boolean} outData
         */
        async postSetPriceModificationCreate(actionAfter = () => {
        }, outData = false) {
            if (outData === false) {
                outData = this.addSetPriceRequestBody;
            }
            try {
                const res = await ServiceApi.post('free', '/map/set-add-modification', outData);
                const {data = {}} = res;
                // const {data: dataCustom = {}} = data;
                // const {rows = [], modification = {}} = dataCustom;
                // actionAfter(rows, modification);

                const {data: modification = {}} = data;
                actionAfter(modification);
            } catch (error) {
                if (error?.response?.data?.data?.set_id[0])
                    alert(error?.response?.data?.data?.set_id[0]);

                console.log(error);
            }
        },
        /**
         * Запрос на удалении модификации
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         * @param {Number} id
         */
        async postSetPriceModificationRemove(actionAfter = () => {
        }, id) {
            try {
                // const res = await ServiceApi.post('free', '/map/set-remove-modification', {set_modification_id: id});
                // const {data = {}} = res;
                // const {data: dataCustom = {}} = data;
                // const {rows = []} = dataCustom;
                // actionAfter(rows);

                await ServiceApi.post('free', '/map/set-remove-modification', {set_modification_id: id});
                actionAfter();
            } catch (error) {
                console.log(error);
            }
        },
        /**
         * Запрос на бронирование
         * @async
         * @param {Array} priceIds - массив цен
         * @param {Function} actionAfter - событие, сработающее после брони
         */
        async postBooking(priceIds = [], actionAfter = () => {
        }) {
            try {
                const requestBody = {
                    set_id: this.id,
                    ids: priceIds
                };
                const res = await ServiceApi.post('free', '/map/book-placements', requestBody);
                const {data = {}} = res;
                const {data: dataBooking = {}} = data;
                actionAfter(dataBooking);
            } catch (error) {
                console.log(error);
            }
        },
        /**
         * Событие массового бронирования
         * @param {Array} selectedRows - выбранные строки
         * @param {Array} selectedDates - выбранные даты
         */
        toBookMass(selectedRows = [], selectedDates = []) {
            const selectedDate = selectedDates[0];
            const priceIdsToBook = this.tableRows.filter(row => {
                const {id = '-1', booking = {}} = row;
                const bookingCurrent = booking[selectedDate] || {};
                const {is_booked: isBooked = true} = bookingCurrent;
                return selectedRows.includes(String(id)) && !isBooked;
            }).map(row => {
                const {booking = {}} = row;
                const bookingCurrent = booking[selectedDate];
                const {id = '-1'} = bookingCurrent;
                return String(id);
            });
            if (priceIdsToBook.length > 0)
                this.postBooking(priceIdsToBook, this.postBookingAfter);
            else
                alert('Нет ни одной конструкции доступной для бронирования')
        },
        /**
         * Событие бронированя
         * @param {String} id - идентификатор цены
         */
        toBook(id = '-1') {
            this.postBooking([id], this.postBookingAfter);
        },
        /**
         * Событие, сработающее после бронирования
         * @param {Object} dataBooking - данные брони
         */
        postBookingAfter(dataBooking) {
            const rowsEditableIds = Object.keys(dataBooking);
            rowsEditableIds.forEach(rowEditableId => {
                const rowEditableIndex = this.tableRows.findIndex(row => {
                    const {id = '-1'} = row;
                    return String(id) === String(rowEditableId);
                });
                if (rowEditableIndex !== -1) {
                    const rowOld = this.tableRows[rowEditableIndex];
                    const {booking: bookingOld = {}} = rowOld;
                    const bookingNew = dataBooking[rowEditableId];
                    const rowNew = {
                        ...rowOld,
                        booking: {
                            ...bookingOld,
                            ...bookingNew
                        }
                    }
                    this.tableRows.splice(rowEditableIndex, 1, rowNew);
                }
            })
        },
        onCheckPrice(priceOptionId = '') {
            this.priceOptionSelected = priceOptionId;
        },
        onChangeSelectedDates(dates) {
            this.selectedDates = [];
            dates.forEach(item => {
                let strMonth = item.month > 9 ? item.month : "0" + item.month;
                this.selectedDates.push(item.year + "-" + strMonth + "-01");
            })
        },
        /**
         * Получение данных для таблицы набора
         */
        updateDataCustomKit() {
            this.postTableBudgetDataCustomKit((rows) => {
                this.afterPostTableBudgetData(rows, 'prices', true, this.tabModeIdActive, this.isUncover);
                this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                this.setTableCustomRows(this.tableRows);
            });
        },
        /**
         * Событие выбора цены в наборе
         * @property {Object} checkData - данные выбора
         */
        async onCheckRow(checkData = {}) {
            this.selectedRows = checkData.rowsIdsSelected;
            try {
                await this.setSelectedTableRows(checkData.row.rowIsSelected ? "remove" : "add", [parseInt(checkData.row.id)]);
            } catch (error) {
                console.log(error);
                throw error;
            }
        },

        async onCheckRowsAll(isSelected = false) {
            if (isSelected) {
                this.selectedRows = this.tableRows.map(row => {
                    const {id = '-1'} = row;
                    return String(id);
                });
            } else {
                this.selectedRows = [];
            }

            try {
                await this.setSelectedTableRows(isSelected ? "select-all" : "remove-all", undefined, {...this.filterItemsSelected});
            } catch (error) {
                console.log(error);
                throw error;
            }
        },

        async getSelectedTableRows() {
            try {
                const {data = {}} = await ServiceApi.get('free', `/map/set-selected/${this.id}`)
                const {data: rows} = data;
                this.selectedRows = [...rows];
            } catch (error) {
                console.log(error);
                throw error;
            }
        },

        /**
         * Событие выбора цены в наборе
         * @param {"select-all" | "remove-all" | "add" | "remove"} type - Тип выборки:
         *   - "select-all": выбор всех элементов
         *   - "remove-all": удаление всех элементов
         *   - "add": добавление элементов
         *   - "remove": удаление элементов
         * @param {number[]} [ids] - Список идентификаторов элементов таблицы
         * @param {
         *     date: any[] | undefined,
         *     format_id: any[] | undefined,
         *     kind_code_id: any[] | undefined,
         *     kind_id: any[] | undefined,
         *     supplier_id: any[] | undefined,
         *     type_id: any[] | undefined,
         *     owner_id: any[] | undefined,
         *     material_id: any[] | undefined
         * } [filter] - фильтр
         */

        async setSelectedTableRows(type, ids, filter) {
            try {
                const response = await ServiceApi.post('free', '/map/set-select', {
                    type,
                    ids,
                    filter: {
                        set_id: parseInt(this.id),
                        ...filter
                    }
                });
                console.log(response);
            } catch (error) {
                console.log(error);
                throw error;
            }
        },

        /**
         * Событие фильтрации по столбцам
         * @param {Object} dataFilter - данные фильтра по столбцам
         */
        onFilter(dataFilter = {}) {
            this.beforeFilterTable(dataFilter);
            this.postTableBudgetDataCustomKit((rows) => {
                this.afterPostTableBudgetData(rows, 'prices', true, this.tabModeIdActive, this.isUncover);
            });
        },
        /**
         * Событие сортировки
         * @param {Object} dataSort - данные сортировки
         */
        onSort(dataSort = {}) {
            this.beforeSortTable(dataSort);
            this.postTableBudgetDataCustomKit((rows) => {
                this.afterPostTableBudgetData(rows, 'prices', true, this.tabModeIdActive, this.isUncover);
            });
        },
        /**
         * Добавить цены в таблицу
         * @property {Array} rows - строки
         */
        addPriceTable(rows = []) {
            if (this.isCurrent) {
                rows.forEach(row => {
                    const currentRowsIds = this.tableRows.map(row => String(row.id));
                    const {id = '-1'} = row;
                    if (!currentRowsIds.includes(id)) {
                        this.afterPostTableBudgetData(rows, 'prices', false, this.tabModeIdActive, this.isUncover)
                    } else {
                        const index = this.tableRows.findIndex(tableRow => String(tableRow.id) === String(id));
                        const rowsAdapted = this.adapterRows([row], 'prices');
                        if (index !== -1) {
                            this.tableRows.splice(index, 1, rowsAdapted[0]);
                            const columnsDatesAdapted = this.adapterColumnsDates([row], 'prices');
                            this.collectBudgetColumns(columnsDatesAdapted);
                        }
                    }
                    this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                    this.setTableCustomRows(this.tableRows);
                });
            }
        },
        /**
         * Удалить цены из таблицу
         * @property {Array} rows - строки
         */
        removePriceTable(rows = []) {
            if (this.isCurrent) {
                rows.forEach(row => {
                    const {id = '-1'} = row;
                    const index = this.tableRows.findIndex(tableRow => String(tableRow.id) === String(id));
                    const rowsAdapted = this.adapterRows([row], 'prices');
                    if (index !== -1) {
                        this.tableRows.splice(index, 1, rowsAdapted[0]);
                        const columnsDatesAdapted = this.adapterColumnsDates([row], 'prices');
                        this.collectBudgetColumns(columnsDatesAdapted);
                    }
                });
                this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                this.setTableCustomRows(this.tableRows);
            }
        },
        /**
         * Удалить строку из таблицы
         * @property {String} rowId - идентификатор набора
         */
        removeRowTable(rowId = '-1') {
            if (this.isCurrent) {
                const index = this.tableRows.findIndex(tableRow => String(tableRow.id) === String(rowId));
                if (index !== -1) {
                    this.tableRows.splice(index, 1);
                    const columnsDatesAdapted = this.adapterColumnsDates(this.tableRows, 'prices');
                    this.collectBudgetColumns(columnsDatesAdapted);
                }
                this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                this.setTableCustomRows(this.tableRows);
            }
        },
        /**
         * Событие удаления набора
         */
        onRemoveTab() {
            const response = confirm("Вы уверены?")

            if (response) {
                this.$emit('removeSet', String(this.id));
                this.$router.push({name: 'bayuerdeskV2'});
            }
        },
        /**
         * Событие изменения настроек панели
         */
        onChangeModificationData(data) {
            this.setModificationData = data;
        },
        async onChangeModificationTitle(newValue, id) {
            if (!newValue && newValue === '') return;
            try {
                await ServiceApi.post('free', '/map/set-rename-modification', {
                    set_modification_id: id,
                    name: newValue
                });

                const foundItem = this.setModificationList.find(item => item.id === id);

                if (foundItem) {
                    foundItem.name = newValue;
                }
            } catch (error) {
                console.log(error);
                throw error;
            }
        },
        /**
         * Создание модификации
         */
        onCreateSetModification() {
            // this.postSetPriceModificationCreate((rows, modification) => {
            //     // this.afterPostTableBudgetData(rows, 'prices', true);
            //     this.afterPostSetPriceModificationCreate(rows, 'prices');
            //     this.setModificationList.unshift(modification);
            //     this.postSummaryData();
            // })

            this.postSetPriceModificationCreate((modification) => {
                this.postTableBudgetDataCustomKit((rows) => {
                    this.afterPostTableBudgetData(rows, 'prices', true, this.tabModeIdActive, this.isUncover);
                });
                this.setModificationList.unshift(modification);
                this.postSummaryData();
            });
        },
        /**
         * Редактирование ячейки
         * @param {Object} data
         */
        onChangeSinglePrice(data) {
            // this.postSetPriceModificationCreate((rows, modification) => {
            //     // this.afterPostTableBudgetData(rows, 'prices', true);
            //     this.afterPostSetPriceModificationCreate(rows, 'prices');
            //     this.setModificationList.unshift(modification);
            //     this.postSummaryData();
            // }, {
            //     set_id: this.id,
            //     dates: data.dates,
            //     selected: data.selected,
            //     change_type: 'edit',
            //     value_type: 'number',
            //     change_value: data.changeValue,
            //     change_attribute: data.changeAttribute
            // })

            this.postSetPriceModificationCreate((modification) => {
                this.postTableBudgetDataCustomKit((rows) => {
                    this.afterPostTableBudgetData(rows, 'prices', true, this.tabModeIdActive, this.isUncover);
                });
                this.setModificationList.unshift(modification);
                this.postSummaryData();
            }, {
                set_id: this.id,
                dates: data.dates,
                selected: data.selected,
                change_type: 'edit',
                value_type: 'number',
                change_value: data.changeValue,
                change_attribute: data.changeAttribute
            })
        },
        onChangePeriod(dateRange = [], rowId = '') {
            this.changePeriod(dateRange, [rowId], this.id);
        },
        async changePeriod(dateRange = [], rowIds = [], kitId = '') {
            const modification = await ServiceEditPricesTable.setChangePeriod(dateRange, rowIds, kitId, {
                set_id: this.id,
                ...this.filterItemsSelected
            });

            this.postTableBudgetDataCustomKit((rows) => {
                this.afterPostTableBudgetData(rows, 'prices', true, this.tabModeIdActive, this.isUncover);
            });
            this.setModificationList.unshift(modification);
            this.postSummaryData();
        },
        /**
         * Удаление модификации
         * @param {Number} id
         */
        onRemoveModification(id) {
            this.postSetPriceModificationRemove(() => {
                // if(rows.length){
                //     this.afterPostTableBudgetData(rows, 'prices', true, this.tabModeIdActive, this.isUncover);
                // }
                // this.setModificationList = this.setModificationList.filter(item => item.id != id);
                // this.postSummaryData();

                this.postTableBudgetDataCustomKit((rows) => {
                    this.afterPostTableBudgetData(rows, 'prices', true, this.tabModeIdActive, this.isUncover);
                });
                this.setModificationList = this.setModificationList.filter(item => item.id != id);
                this.postSummaryData();
            }, id)
        },
        async onOpenCreateOfferPopup() {
            this.isCreateOfferPopupOpen = true;
            const {data} = await ServiceApi.get('free', '/new_map/offer-tags');

            this.offerTags = data.data.map(item => ({id: item, value: item})) || []
        },
        onCloseCreateOfferPopup() {
            this.isCreateOfferPopupOpen = false
        },
        sendFolder(sendingEmail = '', selectedOfferTags = []) {
            this.postSendKit(sendingEmail, selectedOfferTags);
            this.onCloseCreateOfferPopup();
        },
        /**
         * @param {String} sendingEmail - новое название
         * @param {Function} actionAfter - событие, сработающее после отработки запроса
         */
        async postSendKit(sendingEmail = '', selectedOfferTags = [], actionAfter = () => {
        }) {
            const postData = {
                set_name: this.title,
                set_id: this.id,
                receiver_email: sendingEmail,
                tags: selectedOfferTags
            };
            try {
                const res = await ServiceApi.post('free', '/new_map/offer-send', postData);
                const {data = {}} = res;
                if (data.message) {
                    alert(data.message);
                }
                actionAfter();
            } catch (error) {
                console.log(error);
            }
        },
        onRemoveSides() {
            const response = confirm("Вы уверены что хотите удалить конструкции из набора?")
            if (!response) {
                return;
            }
            const postData = {set_id: this.id, action: 'remove-sides', ids: this.selectedRows};
            this.$emit('onLoading', true);
            this.postSyncDataCustom(postData, () => {
                this.selectedRows = [];
                this.tableRows = [];
                this.postTableBudgetDataCustomKit((rows) => {
                    this.afterPostTableBudgetData(rows, 'prices', false, this.tabModeIdActive, this.isUncover);
                    this.setTableCustomPricesSelected(this.tableRowsPricesSelected);
                    this.setTableCustomRows(this.tableRows);
                    this.postSummaryData();
                    this.$emit('onLoading', false);
                });
            });
        },
        /**
         * Событие открытия окна редактирования сторон
         */
        onOpenEditSides() {
            if (this.selectedRows.length > 0)
                this.isEditSides = true;
            else
                alert('Вы не выделили ни одной строки');
        },
        /**
         * Событие редактирования сторон
         * @param {Boolean} isNds - флаг НДС
         */
        onEditSides(isNds = false) {
            this.$emit('onChangeNds', this.selectedRows, isNds);
            this.isEditSides = false;
        },
        /**
         * Экспорт шаблона
         * @returns {Promise<void>}
         */
        async onExportTemplate() {
            let bodyPost = {set_id: this.exportingSetId};
            if (this.currentTemplate.id > 0) {
                bodyPost.template_id = this.currentTemplate.id;
            }
            this.postExportKit(bodyPost, (link) => {
                this.afterPostExportKit(link);
            });
        },
        /**
         * Получение списка шаблонов
         * @returns {Promise<void>}
         */
        async onGetTemplates() {
            this.postTemplateList((templates) => {
                this.afterPostTemplateList(templates);
            });
        },
        /**
         * Сохранение шаблона
         * @param toSaveTemplate
         * @returns {Promise<void>}
         */
        async onSaveTemplate(toSaveTemplate) {
            this.postTemplateSave(toSaveTemplate, (savedTemplate) => {
                this.afterPostTemplateSave(toSaveTemplate, savedTemplate);
            });
        },
        /**
         * Удаление шаблона
         * @returns {Promise<void>}
         */
        async onRemoveTemplate() {
            this.postTemplateRemove(this.currentTemplate.id, () => {
                this.afterPostTemplateRemove();
            });
        },
        /**
         * Событие смены флага ндс
         * @param {String} id - id строки
         * @param {Boolean} value - значение флага
         */
        onChangeNds(id = '', value = false) {
            this.$emit('onChangeNds', [id], value);
        },
        /**
         * Событие закрытия окна редактирования сторон
         */
        onCloseModalEditSides() {
            this.isEditSides = false;
        },
        /**
         * Обновить строки после обновления флага НДС
         * @param {String} id - id строки
         * @param {Boolean} value - значение флага
         * @param {Array} pricesNds - цены в соответствии с флагом НДС
         */
        updateRowsNds(id = '', value = false, pricesNds = []) {
            const rowIndex = this.tableRows.findIndex(row => {
                const {id: rowId = '-1'} = row;
                return String(rowId) === String(id);
            });
            if (rowIndex !== -1) {
                const pricesNdsIds = pricesNds.map(priceNds => {
                    const {id = ''} = priceNds;
                    return String(id);
                });
                const {prices: pricesRow = []} = this.tableRows[rowIndex] || {};
                const pricesRowNew = pricesRow.map(priceRow => {
                    let priceRowNew = {...priceRow};
                    const {id = ''} = priceRow;
                    if (pricesNdsIds.includes(String(id))) {
                        const priceNds = pricesNds.find(priceNds => {
                            const {id: idPriceNds = ''} = priceNds;
                            return String(idPriceNds) === String(id);
                        });
                        const {price: priceNdsValue = ''} = priceNds;
                        priceRowNew = {
                            ...priceRowNew,
                            price: String(priceNdsValue)
                        };
                    }
                    return priceRowNew;
                });
                this.tableRows.splice(rowIndex, 1, {
                    ...this.tableRows[rowIndex],
                    tax: value ? 1 : 0,
                    prices: pricesRowNew
                });
                this.afterPostTableBudgetData(this.tableRows, 'prices', true, this.tabModeIdActive, this.isUncover);
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.modal {
    padding: 35px;

    &-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    &-subtitle {
        font-size: 14px;
    }

    &-input {
        padding: 5px;
        margin: 15px 0px 10px;
        width: 100%;
    }

    &-select {
        width: 100%;
        margin-bottom: 15px;
    }

    &-controls {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.layout-tab {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__container {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__wrapper {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        border-bottom: 1px solid #eee;
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }

    &__buttons-left {
        display: flex;
        align-items: center;
        margin-right: auto;

        &--modified {
            margin-right: 0;
        }
    }

    &__buttons-right {
        display: flex;
        align-items: center;
    }

    &__summary {
        padding: 20px 0 0 0;
    }

    &__body {
        margin-top: 20px;
        display: flex;
        flex-grow: 1;
        overflow: hidden;

        &--modified {
            margin-top: 0;
        }
    }

    &__table-wrapper {
        margin-top: 20px;
        // height: 100%;
        flex-grow: 1;
        overflow: hidden;
    }

    &__table {
        display: inline-block;
        height: 100%;
        max-width: 100%;
    }

    &__left {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    &__right {
        margin-left: 20px;
    }

    &__changes {
        height: 100%;
    }

    &__icon {
        font-size: 16px;
    }

    &__button-right {
        // margin-left: 10px;
        margin-right: 16px;

        &--modified {
            margin-left: 5px;
            padding: 8px 10px;
        }
    }
}

.page-price {
    &__header {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        &-title {
            font-size: 24px;
            font-weight: 400;
            line-height: 24px;
        }

        &-left {
            display: flex;
            align-items: center;
        }

        &-right {
            display: flex;
            align-items: center;
        }
    }

    &__prev {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        border-radius: 10px;
        box-sizing: border-box;
        margin-right: 16px;
        padding: 16px;

        &-icon {
            width: 18px;
            height: 18px;
            font-size: 18px;
            flex-shrink: 0;
        }
    }

    &__action-panel {
        margin-top: 12px;
    }

    &__filter {
        &-panel {
            display: flex;
            // justify-content: space-between;
            margin-top: 30px;

            &-item {
                margin-right: 30px;
            }
        }
    }

    &__uncover {
        display: flex;
        align-items: center;
        max-height: 36px;

        &-title {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            margin-left: 10px;
        }
    }
}
</style>
