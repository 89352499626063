<template>
    <base-button 
        class="booking-status"
        :style="statusStyle"
        @click="toBook"
    >
        {{statusTitle}}
    </base-button>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton';
import SchemePageBooking from '@/schemes/SchemePageBooking';
const {statusesInfo} = SchemePageBooking;
export default {
    name: 'BookingStatus',
    components: {BaseButton},
    /**
     * Входные данные компонента
     * @property {String} status - текущий статус бронирования
     * @property {String} id - идентификатор цены
     */
    props: {
        status: {
            type: String,
            default: 'notAvailable',
            validator: value => ['notAvailable', 'toBook', 'booked'].includes(value)
        },
        id: {
            type: String,
            default: '-1'
        }
    },
    /**
     * Данные компонента
     * @property {Object} statusesInfo - информация статусов бронирования
     */
    data: () => ({
        statusesInfo: statusesInfo
    }),
    computed: {
        /**
         * Заголовк статуса
         * @returns {String}
         */
        statusTitle() {
            const statusObj = this.statusesInfo[this.status];
            const {title = ''} = statusObj;
            return String(title);
        },
        /**
         * Получить стиль статуса
         * @returns {Object}
         */
        statusStyle() {
            const statusObj = this.statusesInfo[this.status];
            const {style = {}} = statusObj;
            return style;
        }
    },
    methods: {
        /**
         * Забронировать
         */
        toBook() {
            if (this.status === 'toBook')
                this.$emit('toBook', this.id);
        }
    }
}
</script>

<style lang="scss" scoped>
    .booking-status {
        width: 105px;
        font-size: 11px;
        font-weight: 400;
        padding: 5px 10px;
        box-sizing: border-box;
    }
</style>