/**
 * Схема для страницы бронирования
 * @property {Object} statusesInfo - информация статусов бронирования
 */
const SchemePageBooking = {
    statusesInfo: {
        notAvailable: {
            title: 'Не доступно',
            style: {'background': '#FF3449'}
        },
        toBook: {
            title: 'Забронировать',
            style: {'background': '#FFB300'}
        },
        booked: {
            title: 'Забронированно',
            style: {'background': '#6EC87B'}
        }
    }
};

export default SchemePageBooking;